import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Fat Bike tracks now on Trailguide`}</h4>
    <p>{`Sykle året rundt? Selv om du bor i en fjell-landsby med syv sammenhengende
måneder med snø? Det er faktisk mulig med fatbike! Nina Gässler deler sine
erfaringer om lidenskapen sin og viser oss noen av favorittstedene hennes på
Trailguide. Oppdag fascinasjonen av vintersykling og få tips til vintereventyret
ditt!`}</p>
    <Image src="/news/fatbiking_3.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Foto: Thomas Brun
    </p>
    <br />
    <h4>{`Nina, gi oss en kort introduksjon om hvem du er, hva du gjør, og hvor du bor.`}</h4>
    <p>{`Jeg er opprinnelig fra Ulm i Tyskland (du hører det fortsatt veldig godt),
født i 1975, har studert idrettsfag ved universitetet i Konstanz og Saarbrücken
og flyttet til Geilo i 2005. Jeg jobber som fitness-instruktør på Vestlia Resort,
som ski-instruktør, zipline-operatør og generell potet på SkiGeilo og driver i
tillegg mitt eget foretak «Geilo Aktiv» (fatbike, truger, terrengsykling,
bikepacking, guiding og instruksjon). De siste årene har jeg blitt NMIG sertifisert
terrengsykkelguide og instruktør.`}</p>
    <br />
    <br />
    <h4>{`Vintersesongen på Geilo er lang. Som syklist må du finne en måte å “håndtere” all snøen. Hvordan ble du interessert i Fat Biking?`}</h4>
    <p>{`I mange år følte jeg egentlig ikke noe stort behov for å sykle så mye på vinteren.
Jeg hadde piggdekk på sykkelen som jeg brukte til og fra jobben, men jeg gikk
mest på ski når jeg skulle ut. Jeg er veldig glad i langrenn. Espen Wethe
(som utviklet fatbikes hos Gresvig da) mente at jeg måtte prøve fatbike, og gjett
om jeg ble hekta. Fatbike sykling var rett og slett et svart hull for meg eller
en verden som åpnet seg. Jeg ble fort en del av fatbikemiljøet, startet Fat Viking
rittet, dro til Alaska og Canada for å lære av veteranene og se hvor stor
fatbikesykling er der. Så kjøpte jeg flere sykler og startet opp Geilo Aktiv
(som het Fatbike Geilo i begynnelsen).`}</p>
    <Image src="/news/fatbiking_8.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Foto: Nina Gässler
    </p>
    <br />
    <h4>{`Hvor lang er Fatbike-sesongen på Geilo?`}</h4>
    <p>{`12 måneder? Det er faktisk gøy å sykle fatbike på sommeren også. Så mye grep og
traksjon. Jeg hadde kun fatbike i noen år før jeg kjøpte en stisykkel igjen.
Nå bruker jeg fatbiken mest på vinteren: november til slutten av april, men
til bikepacking på viddaen eller som «dirt-sykkel» blir den dratt frem på
sommeren også av og til. En av fatbikene mine har dempergaffel og heve-senke-pinne
og en veldig leken geometri.`}</p>
    <br />
    <br />
    <h4>{`Hva fascinerer deg mest med sykling om vinteren?`}</h4>
    <ol>
      <li parentName="ol">{`Hvor mye distanse man klarer å legge bak seg! Det går jo ikke nødvendigvis
fort, men du kan dra med deg alt du trenger for en skikkelig ekspedisjon.`}</li>
      <li parentName="ol">{`Hvor gøy og spennende man kan ha det på en fatbike. Snø er magisk. Tørr,
våt, transformert, finkørnet, grovkørnet, hardpakket, bløt, føykesnø og skare.
Fjellet og dalførene i Hallingdal er magiske.`}</li>
      <li parentName="ol">{`Konsekvensene av å falle er mye mindre enn på sommeren!`}</li>
      <li parentName="ol">{`Ride anywhere days (sykling på skare) – da er det som å sykle på slickrock
i Utah.`}</li>
    </ol>
    <Image src="/news/fatbiking_7.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Foto: Jeff Thevenon
    </p>
    <br />
    <h4>{`På hvilken måte kan en sti være mer egnet for fatbike-sykler? Kan man bruke fatbike på alle typer stier, også der jeg sykler på sommeren, eller er den forbeholdt en spesielle type sti?`}</h4>
    <p>{`Det kommer an på om stien har pakket seg nok den dagen du skal ut. En fatbike
har jo ikke ubegrenset med bæreflate heller, så overflaten må være noenlunde fast.
Stier som brukes av folk som går tur på vinteren pleier å være veldig velegnet,
akkurat som scooterspor, hundesledespor, trugeløyper.
Det som er en utfordring (men gjør ting også spennende) er jo at forholdene
forandrer seg hele tiden. Nysnø, blåsvær, temperaturøkning. Forholdene er aldri
like. Stier dukker opp og forsvinner igjen hele tiden.`}</p>
    <br />
    <br />
    <h4>{`Hvordan lager du fatbike-sporene?`}</h4>
    <p>{`I flere år har jeg bare brukt truger til å gå opp stier. Da blir de ganske smale
og det krever tid til å holde de åpne. Vestlia Resort har investert i en Snowdog
som jeg disponerer. Man må fortsatt etablere et spor med hjelp av truger -
Snowdogen har det lett å velte i ulendt terreng - men så kan man bruke Snowdogen
til å pakke sporet og gjøre det bredere. Man kan selvfølgelig ikke kjøre Snowdogen
hvor som helst uten løyve. Jeg må holde meg innenfor et område som er avklart
med grunneierne. Etter mye vind eller nysnø må man dessverre ofte starte på nytt.
Av og til må jeg bruke en spade også. Det er det som er litt frustrerende:
en sti som var i perfekt tilstand i dag kan være helt ubrukelig i morgen.`}</p>
    <TrailguidePlugin content="/x3O" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
  Interactive Trailguide map with the Fat Bike trails around Geilo.
  <br />
  Move and zoom the map, click on the trails for more information. Click on the
  arrow to the left to open the list of trails.
  <br />
  <a href="https://trailguide.net/x3O">
    {" "}
    Open this map in the Trailguide web-app.
  </a>
    </p>
    <br />
    <h4>{`Hva er din favorittsti?`}</h4>
    <p>{`Av de jeg tråkker selv er det Fossekallen (grønn sti på sommeren), men den er
veldig værutsatt. Jeg ser på stiene mine som et prøveprosjekt. Jeg prøver å
finne områder som ikke er så værutsatte, så skal jeg LAGE favoritt-stien
etterhvert!`}</p>
    <br />
Ellers har jeg noen storfavoritter litt lengre unna: hunde/ scooterspor på Dagalifjellet
og i Seterdalen som ikke er farbar før mars/april. De ligner litt mer det vi kaller
natursti på sommeren.
    <br />
    <br />
Utforstien Anaconda og den nye flytstien i Vestlia blir brukt til offpiste
skikjøring av barn (og skiinstruktører som oppmuntrer barn til å kjøre der ;-)).
Når mange har stått ned med slalåmski der og snøen blir skikkelig hardt er det
fantastisk å sykle fatbike (OBS: det er ikke lov å sykle i slalåmbakken, men du
kan komme deg ned helt til bunns i trollskogen som ligger mellom Trollklubben og
nedre Bjørneløypa.
    <br />
    <br />
    <h4>{`Du er kjent for å sykle langt - hva er den lengste turen du har syklet på vinterføre?`}</h4>
    <p>{`Iditarod Trail Invitational i Alaska 500km (så å si nonstop. Starten går søndag ettermiddag, jeg var i mål torsdag morgen. 3 dager 21t og 2min :-D).
Fat Viking 150km og MANGE turer over 100km i nærområdet.`}</p>
    <Image src="/news/fat_viking_race.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Group in the Fat Viking race.
    </p>
    <br />
    <h4>{`Du er grunnlegger og arrangør av sykkerittet “Fat Viking” - et ritt kun for fatbikes. I år måtte du gjøre noen endringer på grunn av COVID-19-situasjonen for å kunne gjennomføre - hvilke endringer gjorde du? Hvilke tanker har du om endringer som ble gjort?`}</h4>
    <p>{`Ja, Fat Viking er mitt hjertebarn som jeg organiserer sammen med venner og i regi
av Geilo IL.`}</p>
    <br />
    <p>{`Fatbike langdistanse konkurranser er veldig utbredt i Nord Amerika og Kanada,
men ikke i Europa. Jeg ble spurt om ikke jeg kunne arrangere et kvalifiseringsritt
for Iditarod Trail Invitational og det er sånn Fat Viking ble til (150km er
kvalifiseringsritt, men så har vi også 100km og 50km distanse).`}</p>
    <br />
    <p>{`De fleste deltakere kommer vanligvis fra utlandet og da valgte vi å avlyse rittet
på Geilo i år. Isteden inviterer vi til en GLOBAL versjon av rittet: folk kan sykle
fatbike der de er, og de har hele helgen til å samle kilometer på fatbike (fra
Fredag kl 12 til søndag kl 18, FV50, FV100, FV150). Startnummer kan alle skrive
ut selv. Jeg har opprettet et Facebook arrangement der alle skal dokumentere det
de klarte ila helgen. Så skal vi sende Finisher klistermerker til alle som
gjennomførte. `}<a parentName="p" {...{
        "href": "https://www.facebook.com/groups/fatviking"
      }}>{`https://www.facebook.com/groups/fatviking`}</a></p>
    <br />
    <p>{`Responsen er over all forventning. Det er rørernde å se hvor mange som ønsker å
være med. Det føles som om fatbikemiljøet er en stor familie. Det virker som om
folk trengte en slik utfordring. Jeg skulle gjerne hatt alle mine fatbikevenner
her på Geilo denne helgen, men så blir det jo en felles "fatbike vs covid
markering" over hele verden. Det er en fin tanke.`}</p>
    <Image src="/news/fatbiking_1.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Foto: Thomas Brun
    </p>
    <br />
    <h4>{`Du er sykkelguide og instruktør. Tilbyr du guiding og instruksjon på vinterstid også? Hvilke type sykkelturer er det du guider på vinteren?`}</h4>
    <p>{`Ja, jeg har jo 13 sykler til utleie. Turene varierer veldig. De fleste er fornøyd
med 1,5t sykling i dalen, på hundespor, skispor eller de stier som er i god stand.
Noen ganger vil folk på en 3t treningstur eller en dagstur i fjellet. Det er nok
mest tursykling som gjelder. Jeg har også hatt gleden å organisere en treningscamp
for de som vil sykle Fat Viking.`}</p>
    <br />
    <br />
    <h4>{`Har du noen tips til de som ønsker å begynne med vintersykling?`}</h4>
    <p>{`Bruk pogies! Det er styremuffer som hjelper å holde hendene varme, da kan du
sykle med mindre tykke hansker og har bedre kontroll på bremsene og girspakeren.
Alltid ha med ekstra klær og mat som er lett tilgjengelig (boblejakke, hansker,
balaklava, mat som ikke fryser).`}</p>
    <br />
    <p>{`Lær å «lese snøen» slik at du kan sykle der det er hardest/ minst motstand.
Finn passe lufttrykk. En fatbike blir helt fantastisk å sykle på når du velger
riktig lufttrykk med hensyn til overflaten du sykler på. Alltid ha med pumpe
slik at du kan variere/ pumpe opp igjen når du sykler på en vei etc.
Les og følg FATvettreglene:
`}<a parentName="p" {...{
        "href": "https://www.geilo-aktiv.com/no/fatbike/fatvettregler"
      }}>{`https://www.geilo-aktiv.com/no/fatbike/fatvettregler`}</a></p>
    <Image src="/news/fatbiking_4.jpg" className="w-full mx-auto my-12" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Foto: Nina Gässler
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      